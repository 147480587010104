<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Liên hệ </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10" style="display: none;">
        <div class="form-group">
          <router-link to="/admin/contact/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Liên hệ
          </router-link>
        </div>
      </div>

      <div style="display: none;">
        <div>
          <div class="row">
            <div class="col-sm-5 mb-3">
            <input
                type="text"
                name="name"
                class="form-control"
                v-model="search"
                autocomplete="off"
                placeholder="Nhập tên..."
              /><button class="btn btn-sm btn-warning mt-3" @click="onSearch">
                <i class="fa fa-search"></i> TÌM KIẾM
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify"></i> Danh sách : (
              {{ mydata.length }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                     <th  v-if="false">
                          <button  @click="removeAll" type="button" class="btn btn-small btn-danger"> Xoá </button>
                        </th>
                      <th>Tên</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Phòng ban </th>
                      <th> Hình ảnh  </th>
                      <th>Nội dung </th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton width="40px" height="40px"></Skeleton>
                      </td>
                         <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>

                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>

                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>

                   
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                      :Key="key"
                    >
                    
                      
                       <td  v-if="false">
                          <input type="checkbox" :value="value.id" v-model="is_remove" />
                      </td>

                      <td>
                        {{ value.name }}
                      </td>
                      <td>
                        <a :href="'mailto:' + value.email">{{ value.email }}</a>
                      </td>
                      <td>
                        {{ value.phone }}
                      </td>
                      <td >
                        <div v-if="departments!=null && value.departmentId!=null">
                        {{ departments.filter( (n) => n.id==value.departmentId )[0].name}}
                      </div>
                      </td>
                       <td>
                        <a v-if="value.picture!=null" :href="my_url+value.picture" target="_black">
                           <img  :src="my_url+value.picture" style="width: 100px;" />
                         </a>
                      </td>
                      <td>
                        {{ value.content }}
                      </td>
                      <td v-if="id_load !== value.id">
                        
                                  <a
                                    v-if="false"
                                    class="btn btn-sm btn-success"
                                    :href="
                                      '/admin/contact/edit/' + value.id
                                    "
                                  >
                                   <i class="fa-solid fa-envelope"></i>
                                    Gửi mail Phản hồi 
                                  </a>
                        <div
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>
                      </td>
                      <td v-else>
                        <h4>Loading...</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";
export default {
  name: "News.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      search: "",
      page: 1,
      id_load: "",
      departments:null,
      is_remove:[],
      my_url: window.my_url
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },
 
  mounted() {

     axios.get(window.my_api + "api/department/get-departments",
        {
              headers: window.my_header
            }
      ).then((res) => {
      if (res.status == 200) {
        this.departments = res.data.data;


         axios.get(window.my_api + "api/contact/get-contacts",
            {
              headers: window.my_header
            }
          ).then((res) => {
                if (res.status == 200) {
                  this.mydata = res.data.data;
                  this.is_loading = false;
                } else {
                  alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                }
        });



      } else {
        alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
      }
    });


   
  },
  methods: {
    removeAll(){
        
        if(this.is_remove.length > 0){
          console.log(this.is_remove);
          if(confirm("Bạn muốn xoá ? ")){
              axios
                .delete(window.my_api + "api/contact/remove-contact", {
                  data: { contactids: this.is_remove },
                  
                  headers: window.my_header
              
                });
          }

          

        }
        return false;
         
    },
    changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    onSearch() {
      this.is_loading = true;
      axios
        .get(window.my_api + "api/contact/get-contacts?Search=" + this.search,{
              headers: window.my_header
            })
        .then((res) => {
          if (res.status == 200) {
            this.mydata = res.data.data;
            this.page = 1;
          } else {
            alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
          }
          this.is_loading = false;
        });
    },
    removeElement(id) {

          if (confirm("Bạn có muốn xóa?")) {
            this.id_load = id;
            axios
              .delete(window.my_api + "api/contact/remove-contact", {
                data: { contactids: [id] },
                
                headers: window.my_header
            
              })
              .then((res) => {
                if (res.status == 200) {
                 alert("Xóa thành công.");
                  this.mydata = this.mydata.filter((el) => el.id !== id);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        
    },
  },
};
</script>








